import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {SupplierService} from '../../../home/services/supplier.service';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {WorkbookSaveOptions} from 'igniteui-angular-excel';
import {T2DataRefreshService} from '../services/t2-data-refresh.service';
import {NTierMasterAdminService} from "../../n-tier-master-admin/service/n-tier-master-admin.service";

@Component({
	selector: 't2-visibility-data-refresh-spreedsheet',
	templateUrl: './t2-visibility-data-refresh-spreedsheet.component.html',
	styleUrls: ['./t2-visibility-data-refresh-spreedsheet.component.scss'],
	providers: [MessageService]
})
export class T2VisibilityDataRefreshSpreedsheetComponent implements OnInit {

	display = false;
	submitStatus = true;
	confirmStatus: boolean = false;
	fileFromDatabase: any;
	userLoginId: string;
	notifications = [];
	totalRecords: number;
	responsiveOptions: any[];
	cols: any[];
	noNumber = [];
	submitRemainder = false;
	displayDeclarationDialog = false;
	showConfirmButton = false;
	loadMemoryData = false;
	changeCount=0;
	noChangeCount=0;
	notUpdateCount =0;
	newRecords=0;
	total=0;
	newRecordCountFlag=false;
	displayRecordsCountDialog= false;
	fileToBeSubmit : any;
	buttonChange=false;


	constructor( private masterAdminService: NTierMasterAdminService,
		private router: Router, private messageService: MessageService, private spinnerService: SpinnerService,
		// tslint:disable-next-line:max-line-length
		private supplierService: SupplierService, private t2DataRefreshService: T2DataRefreshService, private t2VisibilityService: T2VisibilityService) {
		this.responsiveOptions = [
			{
				breakpoint: '1024px',
				numVisible: 3,
				numScroll: 3
			},
			{
				breakpoint: '768px',
				numVisible: 2,
				numScroll: 2
			},
			{
				breakpoint: '560px',
				numVisible: 1,
				numScroll: 1
			}
		];
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {
		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/login']);
			return;
		} else if (!sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		this.masterAdminService.isSupplierHasT2VDataRefreshAccess(sessionStorage.getItem('emailAddress')).subscribe((t2vDataRefreshAccess) => {
			// this.isT2RefreshTabVisible = t2vDataRefreshAccess;
			if (!t2vDataRefreshAccess) {
				this.router.navigate(['/no-access-for-n-tier']);
			}
		});
		this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		this.cols = [
			// {field: 'id', header: 'S.No'},
			{field: 'subject', header: 'Notifications'}
		];
		if (sessionStorage.getItem('uid').includes('-')) {
			this.initialLoad();
			this.allActiveSupplierNotifications();
		}
		this.t2DataRefreshService.checkIfLessThanFiveSuppliers(sessionStorage.getItem('emailAddress')).subscribe(isLessThanFiveSuppliers => {
			if (isLessThanFiveSuppliers) {
				this.showConfirmButton = true;
			} else {
				this.showConfirmButton = false;

			}
		});
	}

	private allActiveSupplierNotifications() {
		this.t2VisibilityService.getAllActiveSupplierNotifications().subscribe((data) => {
			this.notifications = data;
			this.totalRecords = this.notifications.length;
		});
	}

	public initialLoad() {
		const reportValue = 1;
		const onoffValue = 0;
		this.spinnerService.show();
		this.loadMemoryData = true;
		// this.pushMessage('warn', 'Warning', 'Report Generation may take some time');
		// this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
		this.t2DataRefreshService.generateSupplierReport().subscribe((data) => {
			this.fileFromDatabase = data;
			this.submitStatus = false;
			ExcelUtility.load(this.fileFromDatabase).then((w) => {
				this.spreadsheet.workbook = w;
				console.log('Excel Loaded');
				this.spinnerService.hide();
				this.loadMemoryData = false;
			});
		});
	}

	public openFile(input: any): void {
		this.spinnerService.show();
		if (input.files == null || input.files.length === 0) {
			return;
		}
		ExcelUtility.load(input.files[0]).then((w) => {
			this.spreadsheet.workbook = w;
			this.spinnerService.hide();
			this.submitStatus = false;
			// this.submitRemainder = true;
			console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
		}, (e) => {
			console.error('Workbook Load Error:' + e);
		});
	}

	showDeclarationDialog() {
		this.displayDeclarationDialog = false;
		this.t2DataRefreshService.confirmationStatusUpdate(sessionStorage.getItem('emailAddress')).subscribe((data) => {
			// console.log('Confirmation status updated');
			this.pushMessage('success', 'success',
				'Your Confirmation has been Successfully Updated!!');
			this.submitRemainder = true;
			// this.initialLoad();
			// this.confirmStatus = true;
			// this.submitStatus = false;
			this.showConfirmButton = false;
		}, (e) => {
			// console.log(e.message);
			this.pushMessage('error', 'error', 'Confirmation status updation failed, Please try again later');

		});
	}

	checkLessThanFive() {

		// let totalCount = this.newRecords+this.changeCount+this.noChangeCount+this.notUpdateCount;
		if(this.total <5){
			this.displayDeclarationDialog = true;
		}else {
			this.submitSurvey(false);
		}

		// this.t2DataRefreshService.confirmationStatusUpdate(sessionStorage.getItem('emailAddress')).subscribe((data) => {
		// 	// console.log('Confirmation status updated');
		// 	this.pushMessage('success', 'success',
		// 		'Your Confirmation has been Successfully Updated!!');
		// 	this.submitRemainder = true;
		// 	// this.initialLoad();
		// 	// this.confirmStatus = true;
		// 	// this.submitStatus = false;
		// 	this.showConfirmButton = false;
		// }, (e) => {
		// 	// console.log(e.message);
		// 	this.pushMessage('error', 'error', 'Confirmation status updation failed, Please try again later');
		//
		// });
	}

	isValidEnglishInput(input: string): boolean {
		// const englishWordRegex = /^[a-zA-Z0-9\s.,-^\\/`() ]*$/; // Regex to match only English words, spaces, ".", and ","
		// const englishWordRegex = /^[a-zA-Z0-9\s.,\-^\\\/()`]*$/;
		const englishWordRegex = /^[a-zA-Z0-9\s.,:;\/#&()^"-]*$/;
		return englishWordRegex.test(input);
	}
	isValidAlphabetInput(input: string): boolean {
		// const englishWordRegex = /^[a-zA-Z0-9\s.,-^\\/`() ]*$/; // Regex to match only English words, spaces, ".", and ","
		// const englishWordRegex = /^[a-zA-Z0-9\s.,\-^\\\/()`]*$/;
		const englishWordRegex = /^[a-zA-Z\s]*$/;
		return englishWordRegex.test(input);
	}

	downloadHistoricalT2Data() {
		const reportValue = 2;
		const onoffValue = 1;
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.spinnerService.show();
			this.t2VisibilityService.downLoadFile(data, 'text/csv');
			this.spinnerService.hide();
		});
	}

	downloadBlankT2() {
		const reportValue = 3;
		const onoffValue = 1;
		this.t2VisibilityService.downloadT2Report(sessionStorage.getItem('uid'), reportValue, onoffValue).subscribe((data) => {
			this.spinnerService.show();
			this.t2VisibilityService.downLoadFile(data, 'text/csv');
			this.spinnerService.hide();
		});
	}

	downloadLatestT2Data() {
		const reportValue = 1;
		const onoffValue = 1;
		this.t2DataRefreshService.downLoadFile(this.fileFromDatabase, 'text/csv');
		// this.t2DataRefreshService.generateSupplierReport().subscribe((data) => {
		// 	this.spinnerService.show();
		// 	this.t2DataRefreshService.downLoadFile(data, 'text/csv');
		// 	this.spinnerService.hide();
		// });
	}

	saveSpreadsheet_temp() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		workbook.save(opt, (d) => {
			// tslint:disable-next-line:max-line-length
			this.t2DataRefreshService.upload(d as File, sessionStorage.getItem('uid'), sessionStorage.getItem('emailAddress'), sessionStorage.getItem('fordSiteCode')).subscribe((data) => {
					this.spinnerService.hide();
					this.pushMessage('success', 'success',
						'Data Updated Successfully!');
					this.submitStatus = false;
					this.display = false;
					// this.spreadsheet.workbook = new Workbook();
					this.initialLoad();
				},
				error => {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'Unable to Update Data, Please try again later');
				}
			);
		},()=>{

		});
	}

	onSegmentedItemChange($event) {
		if ($event.title === 'Yes') {
			this.buttonChange = true;
		}else {
			this.buttonChange = false;
		}
	}

	saveSpreadsheet() {
		this.spinnerService.show();
		this.changeCount=0;
		this.noChangeCount=0;
		this.notUpdateCount=0;
		this.newRecords=0;
		this.total =0;
		this.newRecordCountFlag=false;

		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		// @ts-ignore
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			let flag;
			let emptyFlag = true;
			// let i=0;
			// // @ts-ignore
			// do{
			// 	if( workbook.worksheets(0).rows(i).cells(0).value) !== null
			// 	&& workbook.worksheets(0).rows(i).cells(0).value) === ){
			//
			// 	}
			// }while(true);
			// console.log(flag);
			try {

				for (let i = 6;
					 (((workbook.worksheets(0).rows(i).cells(0).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(0).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(1).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(1).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(2).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(2).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(3).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(3).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(4).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(4).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(5).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(5).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(6).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(6).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(7).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(7).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(8).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(8).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(9).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(9).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(10).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(10).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(11).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(11).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(12).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(12).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(13).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(13).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(14).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(14).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(15).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(15).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(16).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(16).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(17).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(17).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(18).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(18).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(19).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(19).value.toString().trim().length) !== 0)
					|| ((workbook.worksheets(0).rows(i).cells(20).value) !== null
					&& (workbook.worksheets(0).rows(i).cells(20).value.toString().trim().length) !== 0)); i++) {

					// console.log('inside for loop for i = ' + i);
					if (workbook.worksheets(0).rows(i).cells(0).value === 'Please use below 500 Rows for adding new Records') {
						this.newRecordCountFlag = true;
						continue;
					}
					emptyFlag = false;
					const rowNumber = workbook.worksheets(0).rows(i).cells(0).rowIndex + 1;
					// console.log('cell 12 value: ', (workbook.worksheets(0).rows(i).cells(12).value));

					if (!((workbook.worksheets(0).rows(i).cells(0).value) === 'Since Invitation Not Validated'
						|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Validate with changes'
						|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Validate with no changes'
						|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Record Validated with no changes'
						|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Record Validated with changes')
						|| ((workbook.worksheets(0).rows(i).cells(35).value) === null && (workbook.worksheets(0).rows(i).cells(0).value) !== 'Validate with changes')
						) {

						this.spinnerService.hide();
						// console.log('%'+workbook.worksheets(0).rows(i).cells(0).value+'%');
						this.pushMessage('error', 'error',
							'Please check Validation State in row number : ' + rowNumber);
						flag = 39;
						this.display = false;
						break;
					}
					if((workbook.worksheets(0).rows(i).cells(0).value) === 'Validate with changes'){

						if (!((workbook.worksheets(0).rows(i).cells(1).value) === 'Yes'
							|| (workbook.worksheets(0).rows(i).cells(1).value) === 'No')) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please check Active status in row number : ' + rowNumber);
							flag = 40;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(2).value) === null
							|| ((workbook.worksheets(0).rows(i).cells(2).value).toString().trim()).length !== 5
							|| (workbook.worksheets(0).rows(i).cells(2).value).toString().trim() === ''
							|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(2).value) === false) {
							flag = 1;
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check GSDB Code in row number :  ' + rowNumber);
							if (((workbook.worksheets(0).rows(i).cells(2).value).toString().trim()).length !== 5) {
								this.pushMessage('error', 'error',
									'GSDB Code should be 5-digit');
							}
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(3).value) === null
							|| (workbook.worksheets(0).rows(i).cells(3).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(3).value).toString().toUpperCase() === 'NA'
							|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(3).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T1 Supplier Site Name in row number : ' + rowNumber);
							if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(3).value) === false) {
								this.pushMessage('error', 'error',
									'English Language is only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(3).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 2;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(4).value) === null
							|| (workbook.worksheets(0).rows(i).cells(4).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(4).value).toString().toUpperCase() === 'NA'
							|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(4).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Supplier Site Name in row number : ' + rowNumber);
							if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(4).value) === false) {
								this.pushMessage('error', 'error',
									'English Language is only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(4).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 2;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(3).value.toString().toUpperCase()) ===
							(workbook.worksheets(0).rows(i).cells(4).value.toString().toUpperCase())) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Both T1 & T2 Supplier Site Names are Same in row number : ' + rowNumber);
							flag = 41;
							this.display = false;
							break;
							// } else if ((workbook.worksheets(0).rows(i).cells(5).value) === null
							// 	|| (workbook.worksheets(0).rows(i).cells(5).value) === '') {
							// 	this.spinnerService.hide();
							// 	this.pushMessage('error', 'error',
							// 		'Please Fill T2 Supplier Site Name (MFG) in row number : ' + rowNumber);
							// 	flag = 3;
							// 	this.display = false;
							// 	break;
						} else if ((workbook.worksheets(0).rows(i).cells(5).value) === null
							|| (workbook.worksheets(0).rows(i).cells(5).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(5).value).toString().toUpperCase() === 'NA'
							|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(5).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Fill T2 Site Address (MFG) in row number : ' + rowNumber);
							if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(5).value) === false) {
								this.pushMessage('error', 'error',
									'English Language is only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(5).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 4;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(6).value) === null
							|| (workbook.worksheets(0).rows(i).cells(6).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(6).value).toString().toUpperCase() === 'NA'
							|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(6).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site City (MFG) in row number : ' + rowNumber);
							if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(6).value) === false) {
								this.pushMessage('error', 'error',
									'English Alphabets only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(6).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 5;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(7).value) === null
							|| (workbook.worksheets(0).rows(i).cells(7).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(7).value).toString().toUpperCase() === 'NA'
							|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(7).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site State/Province (MFG) in row number : ' + rowNumber);
							if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(7).value) === false) {
								this.pushMessage('error', 'error',
									'English Alphabets only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(7).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 6;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(8).value) === null
							|| (workbook.worksheets(0).rows(i).cells(8).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(8).value).toString().toUpperCase() === 'NA'
							|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(8).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site Postal/Zipcode (MFG) in row number : ' + rowNumber);
							if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(8).value) === false) {
								this.pushMessage('error', 'error',
									'English Language is only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(8).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							flag = 7;
							this.display = false;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(9).value) === null
							|| (workbook.worksheets(0).rows(i).cells(9).value).toString().trim() === ''
							|| (workbook.worksheets(0).rows(i).cells(9).value).toString().toUpperCase() === 'NA'
							|| ((workbook.worksheets(0).rows(i).cells(9).value).toString().trim()).length < 3
							|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(9).value) === false) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site Country (MFG) in row number : ' + rowNumber);
							if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(9).value) === false) {
								this.pushMessage('error', 'error',
									'English Alphabets only Allowed');
							}
							if ((workbook.worksheets(0).rows(i).cells(9).value).toString().toUpperCase() === 'NA') {
								this.pushMessage('error', 'error',
									'NA is Not Allowed in Mandatory Fields');
							}
							if(((workbook.worksheets(0).rows(i).cells(9).value).toString().trim()).length < 3){
								this.pushMessage('error', 'error',
									'Please provide the Complete Country Name');
							}
							flag = 8;
							this.display = false;
							break;
						}else if ((workbook.worksheets(0).rows(i).cells(12).value) === null
							|| (workbook.worksheets(0).rows(i).cells(12).value).toString().trim() === ''
							|| ((workbook.worksheets(0).rows(i).cells(12).value) !== 'No'
								&&  (workbook.worksheets(0).rows(i).cells(12).value) !== 'Yes')) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Provide Valid value (Yes/No) in Column M in row number : ' + rowNumber);
							flag= 45;
							this.display = false;
							break;
						}else if ((workbook.worksheets(0).rows(i).cells(12).value) !== null
							&& (workbook.worksheets(0).rows(i).cells(12).value) !== ''
							&& (workbook.worksheets(0).rows(i).cells(12).value) === 'No') {

							if ((workbook.worksheets(0).rows(i).cells(13).value) === null
								|| (workbook.worksheets(0).rows(i).cells(13).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(13).value).toString().toUpperCase() === 'NA'
								|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(13).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Supplier Site Name (SHIP) in row number : ' + rowNumber);
								if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(13).value) === false) {
									this.pushMessage('error', 'error',
										'English Language is only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(13).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								flag = 9;
								this.display = false;
								break;
							} else if ((workbook.worksheets(0).rows(i).cells(14).value) === null
								|| (workbook.worksheets(0).rows(i).cells(14).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(14).value).toString().toUpperCase() === 'NA'
								|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(14).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Address (SHIP) in row number : ' + rowNumber);
								if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(14).value) === false) {
									this.pushMessage('error', 'error',
										'English Language is only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(14).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								flag = 10;
								this.display = false;
								break;
							} else if ((workbook.worksheets(0).rows(i).cells(15).value) === null
								|| (workbook.worksheets(0).rows(i).cells(15).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(15).value).toString().toUpperCase() === 'NA'
								|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(15).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site City (SHIP) in row number : ' + rowNumber);
								if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(15).value) === false) {
									this.pushMessage('error', 'error',
										'English Alphabets only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(15).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								flag = 11;
								this.display = false;
								break;
							} else if ((workbook.worksheets(0).rows(i).cells(16).value) === null
								|| (workbook.worksheets(0).rows(i).cells(16).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(16).value).toString().toUpperCase() === 'NA'
								|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(16).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site State/Province (SHIP) in row number : ' + rowNumber);
								if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(16).value) === false) {
									this.pushMessage('error', 'error',
										'English Alphabets only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(16).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								flag = 12;
								this.display = false;
								break;
							} else if ((workbook.worksheets(0).rows(i).cells(17).value) === null
								|| (workbook.worksheets(0).rows(i).cells(17).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(17).value).toString().toUpperCase() === 'NA'
								|| this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(17).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Postal/Zipcode (SHIP) in row number : ' + rowNumber);
								if (this.isValidEnglishInput(workbook.worksheets(0).rows(i).cells(17).value) === false) {
									this.pushMessage('error', 'error',
										'English Language is only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(17).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								flag = 13;
								this.display = false;
								break;
							} else if ((workbook.worksheets(0).rows(i).cells(18).value) === null
								|| (workbook.worksheets(0).rows(i).cells(18).value).toString().trim() === ''
								|| (workbook.worksheets(0).rows(i).cells(18).value).toString().toUpperCase() === 'NA'
								|| ((workbook.worksheets(0).rows(i).cells(18).value).toString().trim()).length < 3
								|| this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(18).value) === false) {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Country (SHIP) in row number : ' + rowNumber);
								if (this.isValidAlphabetInput(workbook.worksheets(0).rows(i).cells(18).value) === false) {
									this.pushMessage('error', 'error',
										'English Alphabets only Allowed');
								}
								if ((workbook.worksheets(0).rows(i).cells(18).value).toString().toUpperCase() === 'NA') {
									this.pushMessage('error', 'error',
										'NA is Not Allowed in Mandatory Fields');
								}
								if(((workbook.worksheets(0).rows(i).cells(18).value).toString().trim()).length < 3){
									this.pushMessage('error', 'error',
										'Please provide the Complete Country Name');
								}
								flag = 14;
								this.display = false;
								break;
							}
							else {
								flag =18;
							}
						} else {
							flag = 18;
							// continue;
						}
						// if (this.newRecordCountFlag) {
						// 	this.newRecords++;
						// }
						if (workbook.worksheets(0).rows(i).cells(35).value === null){
							this.newRecords++;
						}
						else {
							this.changeCount++;
						}
					}else if((workbook.worksheets(0).rows(i).cells(0).value) === 'Validate with no changes'){
						this.noChangeCount++;
						flag = 18;
					}else if((workbook.worksheets(0).rows(i).cells(0).value) === 'Since Invitation Not Validated'
					|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Record Validated with no changes'
						|| (workbook.worksheets(0).rows(i).cells(0).value) === 'Record Validated with changes'){
						this.notUpdateCount++;
						flag = 18;
					}else {
						flag = 18;
					}



					// else {
					// 	this.spinnerService.hide();
					// 	this.pushMessage('error', 'error',
					// 		'The Value in Column M must be either "Yes" or "No" in row number : ' + rowNumber);
					// 	flag = 30;
					// 	this.display = false;
					// 	break;
					// }
				}
				if (flag === 18) {
					this.total = this.newRecords+this.changeCount+this.noChangeCount+this.notUpdateCount;
					this.spinnerService.hide();
					this.fileToBeSubmit = d;
					this.displayRecordsCountDialog= true;
				}else if( emptyFlag){
					this.spinnerService.hide();
					this.pushMessage('error', 'Error', 'Please provide Data in at least one row');
				}
			}catch (e) {
				this.spinnerService.hide();
				// console.log(e);
				this.pushMessage('error', 'Error', 'Unable to Update Data, Please Check the data & try again later');
			}
		}, (e) => {
			this.spinnerService.hide();
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});

	}

	submitAllWithNoChanges(){
		this.t2DataRefreshService.submitAllDataNoChanges().subscribe((data) => {
			this.pushMessage('success', 'success',
				'Data Updated Successfully!');
			this.initialLoad();
		}, (e) => {
			// console.log(e.message);
			this.pushMessage('error', 'error', 'Confirmation status updation failed, Please try again later');

		});
		// this.spreadsheet.workbook = new Workbook();
	}

	submitSurvey(d: boolean){

			this.t2DataRefreshService.upload(this.fileToBeSubmit as File, sessionStorage.getItem('uid'), sessionStorage.getItem('emailAddress'), sessionStorage.getItem('fordSiteCode')).subscribe((data) => {
					this.spinnerService.hide();
					// if (this.confirmStatus) {
					// 	this.showDeclarationDialog();
					// }
					this.pushMessage('success', 'success',
						'Data Updated Successfully!');
					this.submitStatus = false;
					this.display = false;
					if(d){
						this.t2DataRefreshService.confirmationStatusUpdate(sessionStorage.getItem('emailAddress')).subscribe((data) => {
							// console.log('Confirmation status updated');
							// this.pushMessage('success', 'success',
							// 	'Your Confirmation has been Successfully Updated!!');
							// this.submitRemainder = true;
							this.initialLoad();
							// this.confirmStatus = true;
							// this.submitStatus = false;
							this.showConfirmButton = false;
						}, (e) => {
							// console.log(e.message);
							this.pushMessage('error', 'error', 'Confirmation status updation failed, Please try again later');
						});
					}else {
						this.initialLoad();
					}
					// this.spreadsheet.workbook = new Workbook();

				},
				error => {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'Unable to Update Data, Please try again later');
				}
			);

	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		// this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 8000
		});
	}

}
