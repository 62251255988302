import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {T2Visibility} from "../model/t2_visibility";
import {Router} from "@angular/router";
import {SupplierService} from "../../../home/services/supplier.service";
import {T2DataRefreshService} from "../services/t2-data-refresh.service";
import {SpinnerService} from "../../../services/spinner/spinner.service";
import {MessageService} from "primeng/api";
import {T2VisibilityService} from "../services/t2-visibility.service";
import {NTierMasterAdminService} from "../../n-tier-master-admin/service/n-tier-master-admin.service";
import {IgxSpreadsheetComponent} from "igniteui-angular-spreadsheet";
import {ExcelUtility} from "../../tiger-shark-constraint-tracker/model/excel-utility";
import {Workbook, WorkbookSaveOptions} from "igniteui-angular-excel";

@Component({
  selector: 't2-visibility-data-refresh-record-enrichment',
  templateUrl: './t2-visibility-data-refresh-record-enrichment.component.html',
  styleUrls: ['./t2-visibility-data-refresh-record-enrichment.component.scss']
})
export class T2VisibilityDataRefreshRecordEnrichmentComponent implements OnInit {
	t2VisibilityDashboardForm: FormGroup;
	disableDownloadButton = false;
	t1SupplierSiteNames: any[] = [];
	parentCodes: any[] = [];
	emailIds: any[] = [];
	selectedT1SupplierSiteNames: any[] = [];
	selectedEmailIds: any[] = [];
	selectedParentCodes: any[] = [];
	t2Visibility: T2Visibility;
	value = 0;
	excelFile: any;
	loadMemoryData = false;

	private display: boolean;
	private fileFromDatabase: any;
	isSuperAdmin: boolean;
	displayDeleteDialogue = false;
	rowIds = '';
    submitStatus=false;
	businessName: any;

	displayRecordsCountDialog = false;
	updateCount=0;
	deleteCount =0;
	noChangeCount =0;
	total =0;

	// tslint:disable-next-line:max-line-length
	constructor(private router: Router, private supplierService: SupplierService, private t2DataRefreshService: T2DataRefreshService, private spinnerService: SpinnerService, private fb: FormBuilder, private messageService: MessageService, private t2VisibilityService: T2VisibilityService, private masterAdminService: NTierMasterAdminService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		this.businessName = 'T2Visibility Data Refresh';
		this.t2VisibilityDashboardForm = this.fb.group({
			t1SupplierSiteNames: [{value: '', disabled: false}],
			parentCodes: [{value: '', disabled: false}],
			emailIds: [{value: '', disabled: false}]
		});
		// this.cars = [{brand: 'Volvo'}, {brand: 'BMW'}, {brand: 'Ford'},  {brand: 'Volkswagen'}, {brand: 'Hyuandai'},  {brand: 'Honda'}];
		this.loadT1SupplierSiteNames();
		this.loadSupplierEmails();
		this.loadParentCodes();
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
			this.isSuperAdmin = userHasT2AdminAccess;
		});
	}

	loadT1SupplierSiteNames() {
		this.t2DataRefreshService.loadT1SupplierSiteNames().subscribe(supplierSiteNames => {
			this.t1SupplierSiteNames = [];
			this.t1SupplierSiteNames = supplierSiteNames;
		});
	}

	loadParentCodes() {
		this.t2DataRefreshService.loadParentCodes().subscribe(parentCodes => {
			this.parentCodes = [];
			this.parentCodes = parentCodes;
		});
	}

	loadSupplierEmails() {
		this.t2DataRefreshService.loadSupplierEmails().subscribe(emailIds => {
			this.emailIds = [];
			// @ts-ignore
			this.emailIds = emailIds;
		});
	}

	loadSupplierEmailsBasedOnT1SupplierSite() {
		this.selectedEmailIds = [];
		if (this.selectedT1SupplierSiteNames.length > 0) {
			this.emailIds = [];
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility = new T2Visibility();
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			this.t2DataRefreshService.loadSupplierEmailsBasedOnT1SupplierSite(this.t2Visibility).subscribe(emailIds => {
				// @ts-ignore
				this.emailIds = emailIds;
			});
		} else {
			this.loadSupplierEmails();
		}
	}

	loadParentCodesBasedOnT1SupplierSite() {
		this.selectedParentCodes = [];
		if (this.selectedT1SupplierSiteNames.length > 0) {
			this.parentCodes = [];
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility = new T2Visibility();
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			this.t2DataRefreshService.loadParentCodesBasedOnT1SupplierSite(this.t2Visibility).subscribe(parentCodes => {
				// @ts-ignore
				this.parentCodes = parentCodes;
			});
		} else {
			this.loadParentCodes();
		}
	}

	loadSupplierEmailsBasedOnT1SupplierSiteAndParentCode() {
		this.selectedEmailIds = [];
		if (this.selectedT1SupplierSiteNames.length > 0 || this.selectedParentCodes.length > 0) {
			this.emailIds = [];
			this.t2Visibility = new T2Visibility();
			if (this.selectedT1SupplierSiteNames.length > 0) {
				const t1SupplierSiteNames = [];
				for (const item of this.selectedT1SupplierSiteNames) {
					t1SupplierSiteNames.push(item.name);
				}
				this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			}
			if (this.selectedParentCodes.length > 0) {
				const parentCodes = [];
				for (const item of this.selectedParentCodes) {
					parentCodes.push(item.name);
				}
				this.t2Visibility.parent4DigitCode = parentCodes.join('|');
			}
			this.t2DataRefreshService.loadSupplierEmailsBasedOnT1SupplierSiteAndParentCodes(this.t2Visibility).subscribe(emailIds => {
				// @ts-ignore
				this.emailIds = emailIds;
			});
		} else {
			this.loadSupplierEmails();
		}
	}

	generateReport(isOnline: boolean) {
		// this.excelFile = './assets/image/t2v.xlsx';
		// ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
		// 	this.spreadsheet.workbook = w;
		// 	console.log('Excel Loaded');
		// });
		this.t2Visibility = new T2Visibility();
		if (this.selectedT1SupplierSiteNames.length > 0) {
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
		}
		if (this.selectedParentCodes.length > 0) {
			const parentCodes = [];
			for (const item of this.selectedParentCodes) {
				parentCodes.push(item.name);
			}
			this.t2Visibility.parent4DigitCode = parentCodes.join('|');
		}
		if (this.selectedEmailIds.length > 0) {
			const emailIds = [];
			for (const item of this.selectedEmailIds) {
				emailIds.push(item.name);
			}
			this.t2Visibility.email = emailIds.join('|');
		}
		// tslint:disable-next-line:max-line-length
		if (this.selectedT1SupplierSiteNames.length === 0 || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === 0) || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === this.emailIds.length) || this.selectedT1SupplierSiteNames.length === this.t1SupplierSiteNames.length) {
			this.pushMessage('warn', 'Warning', 'Generating the Complete Report may take some time');
		}
		this.t2DataRefreshService.generateFordAdminReportforDataEnrichment(this.t2Visibility).subscribe((data) => {
			if (isOnline) {
				this.loadOnline(data);
				this.disableDownloadButton = false;
				this.fileFromDatabase = data;
				this.submitStatus = true;
			} else {
				this.disableDownloadButton = false;
				this.downloadOffline(data);
			}
		});
	}

	loadOnline(data: any) {
		this.loadMemoryData = true;
		ExcelUtility.load(data).then((w) => {
			this.spreadsheet.workbook = w;
			console.log('Excel Loaded');
			this.loadMemoryData = false;
		});
	}

	downloadOffline(data: any) {
		this.t2DataRefreshService.downLoadFileDocument(data, 'text/csv', 'T2V_Data_Refresh_Report');
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}


	onT1SupplierSiteNamesChange($event: any) {
		this.loadSupplierEmailsBasedOnT1SupplierSite();
		this.loadParentCodesBasedOnT1SupplierSite();
	}

	onParentCodeChange($event: any) {
		this.loadSupplierEmailsBasedOnT1SupplierSiteAndParentCode();
	}

	onEmailIdsChange($event: any) {
		if (this.selectedEmailIds.length > 0) {
			const emailIds = [];
			for (const item of this.selectedEmailIds) {
				emailIds.push(item.name);
			}
			this.t2Visibility.email = emailIds.join(';!');
		}
	}

	saveSpreadsheet(){
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);

			this.t2DataRefreshService.adminUploadforEnrichment(d as File).subscribe((data) => {
				this.pushMessage('success', 'success',
					'Data Updated Successfully!');
				this.submitStatus = false;
				// this.generateReport(true);
				this.spreadsheet.workbook = new Workbook();
				// const s: Sheet = null;
				// this.spreadsheet.workbook.sheets().add(s);
				// this.router.navigate(['t2-visibility-data-refresh']);
				// this.generateReport(false);
			}, error => {
				this.pushMessage('error', 'error',
					'Unable to Update Data, Please try again later');
			});
		}, (e) => {
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});
	}

	saveSpreadsheetDataRefresh() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		this.displayRecordsCountDialog = false;

		if ( workbook.worksheets(0).rows(5).cells(0).value !== null  && (workbook.worksheets(0).rows(5).cells(0).value).toString().trim() === 'Action'
			&& workbook.worksheets(0).rows(5).cells(1).value !== null  && (workbook.worksheets(0).rows(5).cells(1).value).toString().trim() === 'Validation state'
			&& workbook.worksheets(0).rows(5).cells(2).value !== null  && (workbook.worksheets(0).rows(5).cells(2).value).toString().trim() === 'Active'
			&& workbook.worksheets(0).rows(5).cells(3).value !== null  && (workbook.worksheets(0).rows(5).cells(3).value).toString().trim() === 'T1 Site GSDB (5-digit code)'
			&& workbook.worksheets(0).rows(5).cells(4).value !== null  && (workbook.worksheets(0).rows(5).cells(4).value).toString().trim() === 'T1 Supplier Site Name'
			&& workbook.worksheets(0).rows(5).cells(5).value !== null  && (workbook.worksheets(0).rows(5).cells(5).value).toString().trim() === 'T2 Supplier Site Name'
			&& workbook.worksheets(0).rows(5).cells(6).value !== null  && (workbook.worksheets(0).rows(5).cells(6).value).toString().trim() === 'T2 Site Street Address'
			&& workbook.worksheets(0).rows(5).cells(7).value !== null  && (workbook.worksheets(0).rows(5).cells(7).value).toString().trim() === 'T2 Site City'
			&& workbook.worksheets(0).rows(5).cells(8).value !== null  && (workbook.worksheets(0).rows(5).cells(8).value).toString().trim() === 'T2 Site State / Province'
			&& workbook.worksheets(0).rows(5).cells(9).value !== null  && (workbook.worksheets(0).rows(5).cells(9).value).toString().trim() === 'T2 Site Postal/Zip code'
			&& workbook.worksheets(0).rows(5).cells(10).value !== null  && (workbook.worksheets(0).rows(5).cells(10).value).toString().trim() === 'T2 Site Country'
			&& workbook.worksheets(0).rows(5).cells(11).value !== null  && (workbook.worksheets(0).rows(5).cells(11).value).toString().trim() === 'T2 Site District (If applicable)'
			&& workbook.worksheets(0).rows(5).cells(12).value !== null  && (workbook.worksheets(0).rows(5).cells(12).value).toString().trim() === 'DUNS Number'
			&& workbook.worksheets(0).rows(5).cells(13).value !== null  && (workbook.worksheets(0).rows(5).cells(13).value).toString().trim() === 'Same MFG & Shipping Address?'
			&& workbook.worksheets(0).rows(5).cells(14).value !== null  && (workbook.worksheets(0).rows(5).cells(14).value).toString().trim() === 'T2 Supplier Shipping Site Name'
			&& workbook.worksheets(0).rows(5).cells(15).value !== null  && (workbook.worksheets(0).rows(5).cells(15).value).toString().trim() === 'T2 Site Street Address'
			&& workbook.worksheets(0).rows(5).cells(16).value !== null  && (workbook.worksheets(0).rows(5).cells(16).value).toString().trim() === 'T2 Site City'
			&& workbook.worksheets(0).rows(5).cells(17).value !== null  && (workbook.worksheets(0).rows(5).cells(17).value).toString().trim() === 'T2 Site State / Province'
			&& workbook.worksheets(0).rows(5).cells(18).value !== null  && (workbook.worksheets(0).rows(5).cells(18).value).toString().trim() === 'T2 Site Postal/Zip code'
			&& workbook.worksheets(0).rows(5).cells(19).value !== null  && (workbook.worksheets(0).rows(5).cells(19).value).toString().trim() === 'T2 Site Country'
			&& workbook.worksheets(0).rows(5).cells(20).value !== null  && (workbook.worksheets(0).rows(5).cells(20).value).toString().trim() === 'T2 Site District (If applicable)'
			&& workbook.worksheets(0).rows(5).cells(21).value !== null  && (workbook.worksheets(0).rows(5).cells(21).value).toString().trim() === 'DUNS Number'
			&& workbook.worksheets(0).rows(5).cells(22).value !== null  && (workbook.worksheets(0).rows(5).cells(22).value).toString().trim() === 'UID'
			&& workbook.worksheets(0).rows(5).cells(23).value !== null  && (workbook.worksheets(0).rows(5).cells(23).value).toString().trim() === 'Email'
			&& workbook.worksheets(0).rows(5).cells(24).value !== null  && (workbook.worksheets(0).rows(5).cells(24).value).toString().trim() === 'T1 Supplier Site Code'
			&& workbook.worksheets(0).rows(5).cells(25).value !== null  && (workbook.worksheets(0).rows(5).cells(25).value).toString().trim() === 'Created By'
			&& workbook.worksheets(0).rows(5).cells(26).value !== null  && (workbook.worksheets(0).rows(5).cells(26).value).toString().trim() === 'Created On'
			&& workbook.worksheets(0).rows(5).cells(27).value !== null  && (workbook.worksheets(0).rows(5).cells(27).value).toString().trim() === 'Last Updated By'
			&& workbook.worksheets(0).rows(5).cells(28).value !== null  && (workbook.worksheets(0).rows(5).cells(28).value).toString().trim() === 'Last Updated On'
			&& workbook.worksheets(0).rows(5).cells(29).value !== null  && (workbook.worksheets(0).rows(5).cells(29).value).toString().trim() === 'Last Updated Email'
			&& workbook.worksheets(0).rows(5).cells(30).value !== null  && (workbook.worksheets(0).rows(5).cells(30).value).toString().trim() === 'Confirmation Status'
			&& workbook.worksheets(0).rows(5).cells(31).value !== null  && (workbook.worksheets(0).rows(5).cells(31).value).toString().trim() === 'Confirmed By'
			&& workbook.worksheets(0).rows(5).cells(32).value !== null  && (workbook.worksheets(0).rows(5).cells(32).value).toString().trim() === 'Confirmed On'
			&& workbook.worksheets(0).rows(5).cells(33).value !== null  && (workbook.worksheets(0).rows(5).cells(33).value).toString().trim() === 'Last Invitation Sent Date'
			&& workbook.worksheets(0).rows(5).cells(34).value !== null  && (workbook.worksheets(0).rows(5).cells(34).value).toString().trim() === 'SCE Tier1 Id'
			&& workbook.worksheets(0).rows(5).cells(35).value !== null  && (workbook.worksheets(0).rows(5).cells(35).value).toString().trim() === 'SCE Tier2 Id'
			&& workbook.worksheets(0).rows(5).cells(36).value !== null  && (workbook.worksheets(0).rows(5).cells(36).value).toString().trim() === 'Row Id'
		) {
			// console.log('inside if');
			let flag =99;
			this.updateCount =0;
			this.deleteCount =0;
			this.total =0;
			try {
				for (let i = 6; workbook.worksheets(0).rows(i).cells(36).value !== null;i++) {
					// console.log('inside for loop for i : '+i);
					// console.log('Row ID value for i: '+i+' is : '+workbook.worksheets(0).rows(i).cells(36).value);
					this.total = this.total + 1
					const rowNumber = workbook.worksheets(0).rows(i).cells(0).rowIndex + 1;
					if (workbook.worksheets(0).rows(i).cells(0).value !== null
						&& (workbook.worksheets(0).rows(i).cells(0).value.toString().toString().trim().toUpperCase() === 'UPDATE')) {

						if (!(workbook.worksheets(0).rows(i).cells(1).value.toString().trim() === 'Record Validated with no changes'
							|| workbook.worksheets(0).rows(i).cells(1).value.toString().trim() === 'Record Validated with changes'
							|| workbook.worksheets(0).rows(i).cells(1).value.toString().trim() === 'Since Invitation Not Validated')) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Validation State Column in : ' + rowNumber);
							flag = 1;
							break;
						} else if (!((workbook.worksheets(0).rows(i).cells(2).value) === 'Yes'
							|| (workbook.worksheets(0).rows(i).cells(2).value) === 'No')) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Active Column in : ' + rowNumber);
							flag = 2;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(3).value === null
							|| workbook.worksheets(0).rows(i).cells(3).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(3).value.toString().toUpperCase() === 'NA'
							|| workbook.worksheets(0).rows(i).cells(3).value.toString().trim().length !== 5) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T1 Site GSDB (5-digit code) Column in : ' + rowNumber);
							flag = 3;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(4).value === null
							|| workbook.worksheets(0).rows(i).cells(4).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(4).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T1 Supplier Site Name Column in : ' + rowNumber);
							flag = 4;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(5).value === null
							|| workbook.worksheets(0).rows(i).cells(5).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(5).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Supplier Site Name (MFG) Column in : ' + rowNumber);
							flag = 5;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(4).value.toString().toUpperCase() ===
							workbook.worksheets(0).rows(i).cells(5).value.toString().toUpperCase()) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Both T1 & T2 Supplier Site Names are Same in row number : ' + rowNumber);
							flag = 6;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(6).value === null
							|| workbook.worksheets(0).rows(i).cells(6).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(6).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site Street Address (MFG) Column in : ' + rowNumber);
							flag = 7;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(7).value === null
							|| workbook.worksheets(0).rows(i).cells(7).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(7).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site City (MFG) Column in : ' + rowNumber);
							flag = 8;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(8).value === null
							|| workbook.worksheets(0).rows(i).cells(8).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(8).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site State / Province (MFG) Column in : ' + rowNumber);
							flag = 9;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(9).value === null
							|| workbook.worksheets(0).rows(i).cells(9).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(9).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site Postal/Zip code (MFG) Column in : ' + rowNumber);
							flag = 10;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(10).value === null
							|| workbook.worksheets(0).rows(i).cells(10).value.toString().trim() === ''
							|| workbook.worksheets(0).rows(i).cells(10).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check T2 Site Country (MFG) Column in : ' + rowNumber);
							flag = 11;
							break;
						} else if (!((workbook.worksheets(0).rows(i).cells(2).value) === 'Yes'
							|| (workbook.worksheets(0).rows(i).cells(2).value) === 'No')) {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Same MFG & Shipping Address? Column in : ' + rowNumber);
							flag = 12;
							break;
						} else if ((workbook.worksheets(0).rows(i).cells(13).value) !== null
							&& (workbook.worksheets(0).rows(i).cells(13).value) !== ''
							&& (workbook.worksheets(0).rows(i).cells(13).value) === 'No') {

							if (workbook.worksheets(0).rows(i).cells(14).value === null
								|| workbook.worksheets(0).rows(i).cells(14).value.toString().trim() === ''
								|| workbook.worksheets(0).rows(i).cells(14).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Supplier Shipping Site Name (SHIP) Column in : ' + rowNumber);
								flag = 13;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(15).value === null
								|| workbook.worksheets(0).rows(i).cells(15).value.toString().trim() === ''
								|| workbook.worksheets(0).rows(i).cells(15).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Street Address (SHIP) Column in : ' + rowNumber);
								flag = 14;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(16).value === null
								|| workbook.worksheets(0).rows(i).cells(16).value.toString().trim() === ''
								|| workbook.worksheets(0).rows(i).cells(16).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site City (SHIP) Column in : ' + rowNumber);
								flag = 15;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(17).value === null
								|| workbook.worksheets(0).rows(i).cells(17).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(17).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site State / Province (SHIP) Column in : ' + rowNumber);
								flag = 16;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(18).value === null
								|| workbook.worksheets(0).rows(i).cells(18).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(18).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Postal/Zip code (SHIP) Column in : ' + rowNumber);
								flag = 17;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(19).value === null
								|| workbook.worksheets(0).rows(i).cells(19).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(19).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check T2 Site Country (SHIP) Column in : ' + rowNumber);
								flag = 18;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(22).value === null
								|| workbook.worksheets(0).rows(i).cells(22).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(22).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check UID  Column in : ' + rowNumber);
								flag = 19;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(23).value === null
								|| workbook.worksheets(0).rows(i).cells(23).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(23).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check EMAIL Column in : ' + rowNumber);
								flag = 20;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(24).value === null
								|| workbook.worksheets(0).rows(i).cells(24).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(24).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please T1 Supplier Site Code  Column in : ' + rowNumber);
								flag = 21;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(27).value === null
								|| workbook.worksheets(0).rows(i).cells(27).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(27).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check Last Updated By Column in : ' + rowNumber);
								flag = 22;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(28).value === null
								|| workbook.worksheets(0).rows(i).cells(28).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(28).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check Last Updated On Column in : ' + rowNumber);
								flag = 23;
								break;
							} else if (workbook.worksheets(0).rows(i).cells(29).value === null
								|| workbook.worksheets(0).rows(i).cells(29).value.toString().trim() == ''
								|| workbook.worksheets(0).rows(i).cells(29).value.toString().toUpperCase() === 'NA') {
								this.spinnerService.hide();
								this.pushMessage('error', 'error',
									'Please Check Last Updated Email Column in : ' + rowNumber);
								flag = 24;
								break;
							} else {
								flag = 99;
								if (workbook.worksheets(0).rows(i).cells(0).value.toString().toString().trim().toUpperCase() === 'UPDATE') {
									this.updateCount = this.updateCount + 1;
								} else if (workbook.worksheets(0).rows(i).cells(0).value.toString().toString().trim().toUpperCase() === 'DELETE') {
									this.deleteCount = this.deleteCount + 1;
								}
							}
						} else if (workbook.worksheets(0).rows(i).cells(22).value === null
							|| workbook.worksheets(0).rows(i).cells(22).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(22).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check UID  Column in : ' + rowNumber);
							flag = 19;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(23).value === null
							|| workbook.worksheets(0).rows(i).cells(23).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(23).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check EMAIL Column in : ' + rowNumber);
							flag = 20;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(24).value === null
							|| workbook.worksheets(0).rows(i).cells(24).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(24).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please T1 Supplier Site Code  Column in : ' + rowNumber);
							flag = 21;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(27).value === null
							|| workbook.worksheets(0).rows(i).cells(27).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(27).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Last Updated By Column in : ' + rowNumber);
							flag = 22;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(28).value === null
							|| workbook.worksheets(0).rows(i).cells(28).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(28).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Last Updated On Column in : ' + rowNumber);
							flag = 23;
							break;
						} else if (workbook.worksheets(0).rows(i).cells(29).value === null
							|| workbook.worksheets(0).rows(i).cells(29).value.toString().trim() == ''
							|| workbook.worksheets(0).rows(i).cells(29).value.toString().toUpperCase() === 'NA') {
							this.spinnerService.hide();
							this.pushMessage('error', 'error',
								'Please Check Last Updated Email Column in : ' + rowNumber);
							flag = 24;
							break;
						} else {
							flag = 99;
							if (workbook.worksheets(0).rows(i).cells(0).value.toString().toString().trim().toUpperCase() === 'UPDATE') {
								this.updateCount = this.updateCount + 1;
							}
						}
					}else if (workbook.worksheets(0).rows(i).cells(0).value !== null && workbook.worksheets(0).rows(i).cells(0).value.toString().toString().trim().toUpperCase() === 'DELETE') {
						this.deleteCount = this.deleteCount + 1;
					}
				}

				// console.log('flag : '+flag);
				// console.log('total: '+this.total);
				// console.log('update count: '+this.updateCount);
				// console.log('delete count: '+this.deleteCount);
				if (flag === 99) {
					this.spinnerService.hide();
					this.noChangeCount = this.total - (this.updateCount + this.deleteCount);
					this.displayRecordsCountDialog = true;
				}
			}catch (e) {
				this.spinnerService.hide();
				this.pushMessage('error', 'Error', 'Unable to Update Data, Please Check the data & try again later');
			}

			// workbook.save(opt, (d) => {
			// 	const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			//
			// 	this.t2DataRefreshService.adminUpload(d as File).subscribe((data) => {
			// 			this.pushMessage('success', 'success',
			// 				'Data Updated Successfully!');
			// 			// this.isSubmitVisible = false;
			// 			this.spreadsheet.workbook = new Workbook();
			// 			// const s: Sheet = null;
			// 			// this.spreadsheet.workbook.sheets().add(s);
			// 			// this.router.navigate(['t2-visibility-data-refresh']);
			// 			// this.generateReport(false);
			// 		},
			// 		error => {
			// 			this.pushMessage('error', 'error',
			// 				'Unable to Update Data, Please try again later');
			// 		}
			// 	);
			// }, (e) => {
			// 	this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
			// });
		} else {
			this.spinnerService.hide();
			this.messageService.add({
				severity: 'error',
				summary: 'Template Error',
				detail: 'Please Check the File Format. ',
				life: 100000
			});
		}

	}


	cancelButton() {
		this.displayDeleteDialogue = false;
		this.rowIds = '';
	}

	deleteRecords(){
		this.t2DataRefreshService.deleteByRowIds(this.rowIds).subscribe((data) => {
				this.displayDeleteDialogue = false;
				this.pushMessage('success', 'success',
					'Given RowIds Data Deleted Successfully!');
				this.generateReport(true);
			},
			error => {
				this.pushMessage('error', 'error',
					'Unable to Update Data, Please try again later');
			}
		);
		// this.displayDeleteDialogue = false;
		//
		// this.pushMessage('success', 'success',
		// 	'Data Deleted Successfully for Given ROW IDs');
	}

	public openFile(input: any): void {
		this.spinnerService.show();
		if (input.files == null || input.files.length === 0) {
			this.spinnerService.hide();
			return;
		}

		ExcelUtility.load(input.files[0]).then((w) => {

			// const workbook = w;
			this.spreadsheet.workbook = w;
			this.submitStatus = true;
			// this.spreadsheet.activeWorksheet.protect();
			this.spinnerService.hide();
			console.log(this.spreadsheet.workbook.worksheets('Sheet1')._r);
			this.spinnerService.hide();
		}, (e) => {
			console.error('Workbook Load Error:' + e);
			this.spinnerService.hide();
		});

	}


}
