import { Component, OnInit } from '@angular/core';
import {T2VisibilityService} from "../services/t2-visibility.service";
import {SupplierService} from "../../../home/services/supplier.service";

@Component({
  selector: 't2v-data-refresh-help',
  templateUrl: './t2v-data-refresh-help.component.html',
  styleUrls: ['./t2v-data-refresh-help.component.scss']
})
export class T2VDataRefreshHelpComponent implements OnInit {
  businessName : any;

  constructor(private t2VisibilityService: T2VisibilityService, private supplierService: SupplierService) {
  }

  ngOnInit() {
    this.businessName = sessionStorage.getItem('Admin');
    this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');

  }

  download(contentType: string, num: number) {

    if (num === 22) {
      this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
        this.t2VisibilityService.downloadFileMp4(data, contentType, 'T2V_Refresh_Supplier_Training_Tutorial');
      });

    } else if (num === 23) {
      this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
        this.t2VisibilityService.downLoadFileDocument(data, contentType, 'T2V_Refresh_Supplier_Training_Document');
      });

    } else if (num === 24) {
      this.t2VisibilityService.helpEndPointDownload(num).subscribe((data) => {
        this.t2VisibilityService.downLoadFileDocument(data, contentType, 'T2V_Refresh_FAQs');
      });

    }

  }

}
