import {Component, OnInit} from '@angular/core';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {MessageService} from 'primeng/api';
import {emptyT2Email, T2VisibilityEmail} from '../model/t2_email';
import {emptyT2EmailTrigger, T2VisibilityEmailTrigger} from '../model/t2_email_trigger';
import {SupplierService} from '../../../home/services/supplier.service';
import {
	Tier1EComponentShortageAdminService
} from '../../tier1-e-component-shortage/service/tier1-e-component-shortage-admin.service';
import {SustainabilitySurveyService} from '../../sustainability/Servies/sustainability-survey.service';
import {Router} from "@angular/router";

@Component({
	selector: 't2-visibility-email',
	templateUrl: './t2-visibility-email.component.html',
	styleUrls: ['./t2-visibility-email.component.scss']
})
export class T2VisibilityEmailComponent implements OnInit {

	editor = ClassicEditor;
	customColorPalette = [
		{
			color: '#E64C4C',
			label: 'Red',
		},
		{
			color: '#E6994C',
			label: 'Orange',
		},
		{
			color: '#E6E64C',
			label: 'yellow',
		},

		// ...
	];
	draftContent: any;
	config = {
		toolbar: [
			'undo',
			'redo',
			'|',
			'heading',
			'fontFamily',
			'fontSize',
			'|',
			'bold',
			'italic',
			'underline',
			'fontColor',
			'fontBackgroundColor',
			'highlight',
			'|',
			'link',
			// 'CKFinder',
			// 'imageUpload',
			'mediaEmbed',
			'|',
			'alignment',
			'bulletedList',
			'numberedList',
			'|',
			'indent',
			'outdent',
			'|',
			'insertTable',
			'blockQuote',
			'specialCharacters',
		],
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableProperties',
				'tableCellProperties',
			],
			tableProperties: {
				borderColors: this.customColorPalette,
				backgroundColors: this.customColorPalette,
				defaultProperties: {
					borderStyle: 'dashed',
					borderColor: 'hsl(90, 75%, 60%)',
					borderWidth: '3px',
					alignment: 'left',
					width: '100px',
					height: '100px',
				},
			},
			tableCellProperties: {
				borderColors: this.customColorPalette,
				backgroundColors: this.customColorPalette,
				defaultProperties: {
					horizontalAlignment: 'center',
					verticalAlignment: 'bottom',
					padding: '10px',
				},
			},
		},

		fontColor: {
			colors: [
				{
					color: '#E64C4C',
					label: 'Red',
				},
				{
					color: '#E6994C',
					label: 'Orange',
				},
				{
					color: '#E6E64C',
					label: 'Yellow',
				},
			],
		},

		language: 'id',
		image: {
			toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
		},
	};
	// tslint:disable-next-line:max-line-length
	supplierEmailTemplateList: any = [{templateName: 'Tier2_Remainder_Email'}, {templateName: 'Wpp_Primary_Email'}, {templateName: 'Wpp_Adhoc_Email'}, {templateName: 'Wpp_Supplier_Update_Email'}, {templateName: 'Wpp_Outbound_Status_Email'}];
	filterConditionEcomp: any = [
		{
			condition: 'no_check',
			displayValue: 'All Supplier'
		}, {
			condition: 'supl_name',
			displayValue: 'Supplier Name'
		}, {
			condition: 'ship_site_code',
			displayValue: 'Ship Site Code'
		}];
	filterCondition: any = [
		{
			condition: 'no_check',
			displayValue: 'All Supplier'
		}, {
			condition: 'check',
			displayValue: 'Not Responded Supplier'
		}, {
			condition: 'ship_site_code',
			displayValue: 'Ship Site Code'
		}];
	filterConditionT2VDataRefresh: any = [
		{
			condition: 'no_check',
			displayValue: 'All T1 GSDB Codes'
		}, {
			condition: 't1_site_gsdb',
			displayValue: 'T1 GSDB Codes'
		}];
	templateName: any;
	popUpTemplateName: any;
	condition: any;
	display: boolean;
	shipSiteCodes = '';
	tier1GsdbCodes = '';
	templateDraft: T2VisibilityEmail;
	triggerEmailModel: T2VisibilityEmailTrigger;
	selectedSupplierNames: any[] = [];
	ccList: string[];
	displayShipSiteCodeTag = false;
	displayT1GsdbTag = false;
	subject: any;
	businessName: string;

	tier1SupplierList = [];
	displaySupplierName = false;

	// tslint:disable-next-line:max-line-length
	displayDialogueConfirmationDialog = false;
	emailsTriggeringData: any;
	conditionForReview: any;
	supplierCountForReview: any;
	templateNameForReview: any;
	fromAddressForReview: any;

	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private t2VisibilityService: T2VisibilityService, private messageService: MessageService, private tier1EComponentShortageAdminService: Tier1EComponentShortageAdminService, private sustainabilitySurveyService: SustainabilitySurveyService, private router: Router) {
	}

	ngOnInit() {

		this.businessName = sessionStorage.getItem('Admin');

		if (this.businessName === 'T2Visibility' || this.businessName === 'SemiSupp'  || this.businessName === null) {
			this.router.navigate(['/']);
			return;
		}

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}

		this.businessName = sessionStorage.getItem('Admin');
		if (this.businessName === 'Project R') {
			console.log('Project R');
			this.supplierService.pushSelectedSurvey('Project R');
		} else if (this.businessName === 'sustainability survey') {
			console.log('sustainability survey');
			this.supplierService.pushSelectedSurvey('sustainability survey');
		} else if (this.businessName === 'T2Visibility') {
			console.log('T2Visibility');
			this.supplierService.pushSelectedSurvey('T2Visibility');
		// } else if (this.businessName === 'SemiSupp') {
		// 	this.supplierService.pushSelectedSurvey('SemiSupp');
		} else if (this.businessName === 'T2Visibility Data Refresh') {
			console.log('T2Visibility Data Refresh');
			this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		}
		this.findAllEmailTemplateName(this.businessName);

	}

	findAllEmailTemplateName(businessName) {
		this.t2VisibilityService.findAllEmailTemplateName(businessName).subscribe((data) => {
			this.supplierEmailTemplateList = data;
		});
	}

	onTemplateChange($event: any) {
		// alert(JSON.stringify(this.templateName));
		this.draftContent = '';
		this.ccList = [];
		this.subject = '';
		this.draftContent = this.templateName.emailContent;
		this.ccList = this.templateName.ccList.split(',');
		this.subject = this.templateName.subject;
	}

	saveEmailDraft() {
		if (this.templateName) {
			// const businessName = sessionStorage.getItem('Admin');
			this.templateDraft = new T2VisibilityEmail(emptyT2Email);
			this.templateDraft.templateName = this.templateName.templateName;
			this.templateDraft.emailContent = this.draftContent;
			this.templateDraft.ccList = this.ccList.toString();
			this.templateDraft.subject = this.subject;
			this.templateDraft.businessName = this.businessName;
			this.t2VisibilityService.createOrUpdateEmailDraft(this.templateDraft).subscribe((data) => {
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Successfully Drafted',
					life: 100000
				});
				this.draftContent = '';
				this.ccList = [];
				this.subject = '';
				this.supplierEmailTemplateList = data;
			}, (err) => {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Something went wrong',
					life: 100000
				});
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please Select Template Name',
				life: 100000
			});
		}

	}

	triggerEmailSupplierCount() {
		// tslint:disable-next-line:max-line-length
		if (this.businessName === 'Project R' || this.businessName === 'sustainability survey' || this.businessName === 'T2Visibility Data Refresh') {
			// this.display = false;
			this.triggerEmailModel = new T2VisibilityEmailTrigger(emptyT2EmailTrigger);
			this.triggerEmailModel.templateName = this.popUpTemplateName.templateName;
			this.triggerEmailModel.condition = this.condition.condition;
			// if(this.businessName === 'T2Visibility Data Refresh'){
			// 	this.triggerEmailModel.tier1GsdbCodes = this.tier1GsdbCodes;
			// }else {
			this.triggerEmailModel.shipSiteCodes = this.shipSiteCodes;
			// }
			this.triggerEmailModel.businessName = this.businessName;
			if (this.selectedSupplierNames.length > 0) {
				const t1ModuleSupplierNames = [];
				for (const item of this.selectedSupplierNames) {
					t1ModuleSupplierNames.push(item.tier1Supplier);
				}
				this.triggerEmailModel.supplierNames = t1ModuleSupplierNames.join('|');
			}
			// this.triggerEmailModel.supplierNames = this.selectedSupplierNames;
			// alert(JSON.stringify(this.selectedSupplierNames));
			if (this.condition && this.popUpTemplateName) {
				// tslint:disable-next-line:max-line-length
				this.t2VisibilityService.triggerEmailForT2Count(this.triggerEmailModel).subscribe((data) => {
					this.emailsTriggeringData = data['emailSetupEntityList'];
					this.conditionForReview = data['condition'];
					this.supplierCountForReview = data['suppliersCount'];
					this.templateNameForReview = data['templateName'];
					this.fromAddressForReview = data['fromAddress'];
					this.displayDialogueConfirmationDialog = true;
				});
			} else if (!this.condition && !this.popUpTemplateName) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please choose Template Name and Condition',
					life: 100000
				});
			} else if (!this.condition) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please choose condition',
					life: 100000
				});
			} else if (!this.popUpTemplateName) {
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail: 'Please choose Template Name',
					life: 100000
				});
			}
		} else {
			this.triggerEmail();
		}
	}

	triggerEmail() {
		// this.display = true;
		this.triggerEmailModel = new T2VisibilityEmailTrigger(emptyT2EmailTrigger);
		this.triggerEmailModel.templateName = this.popUpTemplateName.templateName;
		this.triggerEmailModel.condition = this.condition.condition;
		this.triggerEmailModel.shipSiteCodes = this.shipSiteCodes;
		this.triggerEmailModel.businessName = this.businessName;
		if (this.selectedSupplierNames.length > 0) {
			const t1ModuleSupplierNames = [];
			for (const item of this.selectedSupplierNames) {
				t1ModuleSupplierNames.push(item.tier1Supplier);
			}
			this.triggerEmailModel.supplierNames = t1ModuleSupplierNames.join('|');
		}
		// this.triggerEmailModel.supplierNames = this.selectedSupplierNames;
		// alert(JSON.stringify(this.selectedSupplierNames));
		if (this.condition && this.popUpTemplateName) {
			// tslint:disable-next-line:max-line-length
			this.t2VisibilityService.triggerEmailForT2(this.triggerEmailModel).subscribe((data) => {
				this.messageService.add({
					severity: 'success',
					summary: 'Success',
					detail: 'Mail Triggered',
					life: 100000
				});
			});
			this.displayDialogueConfirmationDialog = false;
			this.display = false;
		} else if (!this.condition && !this.popUpTemplateName) {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please choose Template Name and Condition',
				life: 100000
			});
		} else if (!this.condition) {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please choose condition',
				life: 100000
			});
		} else if (!this.popUpTemplateName) {
			this.messageService.add({
				severity: 'error',
				summary: 'Error',
				detail: 'Please choose Template Name',
				life: 100000
			});
		}
		this.popUpTemplateName = [];
		this.condition = [];
		this.displayShipSiteCodeTag = false;
		this.displaySupplierName = false;
		this.shipSiteCodes = '';
	}

	onTemplateCondition($event: any) {

		// console.log(this.condition);
		if (this.businessName === 'T2Visibility Data Refresh') {
			if (this.condition.condition === 't1_site_gsdb') {
				this.displayT1GsdbTag = true;
			} else {
				this.shipSiteCodes = '';
				this.displayT1GsdbTag = false;
			}
		}
		if (this.condition.condition === 'ship_site_code') {
			this.displayShipSiteCodeTag = true;
			this.displaySupplierName = false;
		} else if (this.condition.condition === 'supl_name' && this.businessName === 'SemiSupp') {
			this.tier1EComponentShortageAdminService.getAllTier1Suppliers().subscribe((data) => {
				this.tier1SupplierList = data;
			});
			this.displaySupplierName = true;
			this.displayShipSiteCodeTag = false;
		} else if (this.condition.condition === 'supl_name' && this.businessName === 'sustainability survey') {
			this.sustainabilitySurveyService.getAllTier1Suppliers1(this.businessName).subscribe((data) => {
				this.tier1SupplierList = data;
			});
			this.displaySupplierName = true;
			this.displayShipSiteCodeTag = false;
		} else if (this.condition.condition === 'supl_name' && this.businessName === 'Project R') {
			this.sustainabilitySurveyService.getAllTier1Suppliers1(this.businessName).subscribe((data) => {
				this.tier1SupplierList = data;
			});
			this.displaySupplierName = true;
			this.displayShipSiteCodeTag = false;
		} else {
			this.displaySupplierName = false;
			this.displayShipSiteCodeTag = false;
		}

	}

	cancelButton() {
		this.display = false;
		this.shipSiteCodes = '';
		this.condition = [];
		this.popUpTemplateName = [];
		this.displayShipSiteCodeTag = false;
		this.displayT1GsdbTag = false;
	}
}
