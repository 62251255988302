import {
	Component,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	FormBuilder,
	FormGroup
} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {T2Visibility} from '../model/t2_visibility';
import {SupplierService} from '../../../home/services/supplier.service';
import {ExcelUtility} from '../../tiger-shark-constraint-tracker/model/excel-utility';
import {IgxSpreadsheetComponent} from 'igniteui-angular-spreadsheet';
import {WorkbookSaveOptions} from 'igniteui-angular-excel';
import {Router} from '@angular/router';
import {SpinnerService} from '../../../services/spinner/spinner.service';
import {NTierMasterAdminService} from '../../n-tier-master-admin/service/n-tier-master-admin.service';
import {T2DataRefreshService} from '../services/t2-data-refresh.service';

@Component({
	selector: 't2-visibility-data-refresh',
	templateUrl: './t2-visibility-data-refresh.component.html',
	styleUrls: ['./t2-visibility-data-refresh.component.scss']
})
export class T2VisibilityDataRefreshComponent implements OnInit {
	t2VisibilityDashboardForm: FormGroup;
	disableDownloadButton = false;
	t1SupplierSiteNames: any[] = [];
	parentCodes: any[] = [];
	emailIds: any[] = [];
	selectedT1SupplierSiteNames: any[] = [];
	selectedEmailIds: any[] = [];
	selectedParentCodes: any[] = [];
	t2Visibility: T2Visibility;
	value = 0;
	excelFile: any;
	loadMemoryData = false;

	private display: boolean;
	private fileFromDatabase: any;
	isSuperAdmin: boolean;

	// tslint:disable-next-line:max-line-length
	constructor(private router: Router, private supplierService: SupplierService, private t2DataRefreshService: T2DataRefreshService, private spinnerService: SpinnerService, private fb: FormBuilder, private messageService: MessageService, private t2VisibilityService: T2VisibilityService, private masterAdminService: NTierMasterAdminService) {
	}

	@ViewChild('spreadsheet', {static: false, read: IgxSpreadsheetComponent})
	public spreadsheet: IgxSpreadsheetComponent;

	ngOnInit() {

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		this.t2VisibilityDashboardForm = this.fb.group({
			t1SupplierSiteNames: [{value: '', disabled: false}],
			parentCodes: [{value: '', disabled: false}],
			emailIds: [{value: '', disabled: false}]
		});
		// this.cars = [{brand: 'Volvo'}, {brand: 'BMW'}, {brand: 'Ford'},  {brand: 'Volkswagen'}, {brand: 'Hyuandai'},  {brand: 'Honda'}];
		this.loadT1SupplierSiteNames();
		this.loadSupplierEmails();
		this.loadParentCodes();
		// tslint:disable-next-line:max-line-length
		this.masterAdminService.isUserHasFunctionAdminAccess(sessionStorage.getItem('uid'), 'T2 Visibility Super Admin').subscribe(userHasT2AdminAccess => {
			this.isSuperAdmin = userHasT2AdminAccess;
		});
	}

	loadT1SupplierSiteNames() {
		this.t2DataRefreshService.loadT1SupplierSiteNames().subscribe(supplierSiteNames => {
			this.t1SupplierSiteNames = [];
			this.t1SupplierSiteNames = supplierSiteNames;
		});
	}

	loadParentCodes() {
		this.t2DataRefreshService.loadParentCodes().subscribe(parentCodes => {
			this.parentCodes = [];
			this.parentCodes = parentCodes;
		});
	}

	loadSupplierEmails() {
		this.t2DataRefreshService.loadSupplierEmails().subscribe(emailIds => {
			this.emailIds = [];
			// @ts-ignore
			this.emailIds = emailIds;
		});
	}

	loadSupplierEmailsBasedOnT1SupplierSite() {
		this.selectedEmailIds = [];
		if (this.selectedT1SupplierSiteNames.length > 0) {
			this.emailIds = [];
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility = new T2Visibility();
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			this.t2DataRefreshService.loadSupplierEmailsBasedOnT1SupplierSite(this.t2Visibility).subscribe(emailIds => {
				// @ts-ignore
				this.emailIds = emailIds;
			});
		} else {
			this.loadSupplierEmails();
		}
	}

	loadParentCodesBasedOnT1SupplierSite() {
		this.selectedParentCodes = [];
		if (this.selectedT1SupplierSiteNames.length > 0) {
			this.parentCodes = [];
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility = new T2Visibility();
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			this.t2DataRefreshService.loadParentCodesBasedOnT1SupplierSite(this.t2Visibility).subscribe(parentCodes => {
				// @ts-ignore
				this.parentCodes = parentCodes;
			});
		} else {
			this.loadParentCodes();
		}
	}

	loadSupplierEmailsBasedOnT1SupplierSiteAndParentCode() {
		this.selectedEmailIds = [];
		if (this.selectedT1SupplierSiteNames.length > 0 || this.selectedParentCodes.length > 0) {
			this.emailIds = [];
			this.t2Visibility = new T2Visibility();
			if (this.selectedT1SupplierSiteNames.length > 0) {
				const t1SupplierSiteNames = [];
				for (const item of this.selectedT1SupplierSiteNames) {
					t1SupplierSiteNames.push(item.name);
				}
				this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
			}
			if (this.selectedParentCodes.length > 0) {
				const parentCodes = [];
				for (const item of this.selectedParentCodes) {
					parentCodes.push(item.name);
				}
				this.t2Visibility.parent4DigitCode = parentCodes.join('|');
			}
			this.t2DataRefreshService.loadSupplierEmailsBasedOnT1SupplierSiteAndParentCodes(this.t2Visibility).subscribe(emailIds => {
				// @ts-ignore
				this.emailIds = emailIds;
			});
		} else {
			this.loadSupplierEmails();
		}
	}

	generateReport(isOnline: boolean) {
		// this.excelFile = './assets/image/t2v.xlsx';
		// ExcelUtility.loadFromUrl(this.excelFile).then((w) => {
		// 	this.spreadsheet.workbook = w;
		// 	console.log('Excel Loaded');
		// });
		this.t2Visibility = new T2Visibility();
		if (this.selectedT1SupplierSiteNames.length > 0) {
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
		}
		if (this.selectedParentCodes.length > 0) {
			const parentCodes = [];
			for (const item of this.selectedParentCodes) {
				parentCodes.push(item.name);
			}
			this.t2Visibility.parent4DigitCode = parentCodes.join('|');
		}
		if (this.selectedEmailIds.length > 0) {
			const emailIds = [];
			for (const item of this.selectedEmailIds) {
				emailIds.push(item.name);
			}
			this.t2Visibility.email = emailIds.join('|');
		}
		// tslint:disable-next-line:max-line-length
		if (this.selectedT1SupplierSiteNames.length === 0 || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === 0) || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === this.emailIds.length) || this.selectedT1SupplierSiteNames.length === this.t1SupplierSiteNames.length) {
			this.pushMessage('warn', 'Warning', 'Generating the Complete Report may take some time');
		}
		this.t2DataRefreshService.generateFordAdminReport(this.t2Visibility).subscribe((data) => {
			if (isOnline) {
				this.loadOnline(data);
				this.disableDownloadButton = false;
				this.fileFromDatabase = data;
			} else {
				this.disableDownloadButton = false;
				this.downloadOffline(data);
			}
		});
	}

	downloadReport() {
		this.downloadOffline(this.fileFromDatabase);
	}

	generateRawReport(isOnline: boolean) {
		this.t2Visibility = new T2Visibility();
		if (this.selectedT1SupplierSiteNames.length > 0) {
			const t1SupplierSiteNames = [];
			for (const item of this.selectedT1SupplierSiteNames) {
				t1SupplierSiteNames.push(item.name);
			}
			this.t2Visibility.t1SupplierSiteName = t1SupplierSiteNames.join('|');
		}
		if (this.selectedParentCodes.length > 0) {
			const parentCodes = [];
			for (const item of this.selectedParentCodes) {
				parentCodes.push(item.name);
			}
			this.t2Visibility.parent4DigitCode = parentCodes.join('|');
		}
		if (this.selectedEmailIds.length > 0) {
			const emailIds = [];
			for (const item of this.selectedEmailIds) {
				emailIds.push(item.name);
			}
			this.t2Visibility.email = emailIds.join('|');
		}
		// tslint:disable-next-line:max-line-length indent
		if (this.selectedT1SupplierSiteNames.length === 0 || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === 0) || (this.selectedT1SupplierSiteNames.length === 0 && this.selectedEmailIds.length === this.emailIds.length) || this.selectedT1SupplierSiteNames.length === this.t1SupplierSiteNames.length) {
			this.pushMessage('warn', 'Warning', 'Generating the Complete Report may take some time');
		}
		// tslint:disable-next-line:indent
		this.t2VisibilityService.generateRawReport(this.t2Visibility).subscribe((data) => {
			if (isOnline) {
				this.loadOnline(data);
				this.disableDownloadButton = false;
			} else {
				this.downloadOffline(data);
			}
		});
	}

	removeDuplicatesAdhoc() {
		this.t2DataRefreshService.removeDuplicatesAdhoc().subscribe((data) => {
			this.spinnerService.show();
			this.pushMessage('success', 'success', 'Duplicates Removed Successfully!');
			// this.value = 0;
			this.spinnerService.hide();
		}, (e) => {
			this.pushMessage('error', 'error', 'Duplicate removal failed, Please try again later');

		});
	}

	loadOnline(data: any) {
		this.loadMemoryData = true;
		ExcelUtility.load(data).then((w) => {
			this.spreadsheet.workbook = w;
			console.log('Excel Loaded');
			this.loadMemoryData = false;
		});
	}

	downloadOffline(data: any) {
		this.t2DataRefreshService.downLoadFileDocument(data, 'text/csv', 'T2V_Data_Refresh_Report');
	}

	private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		this.messageService.add({
			severity: msgSeverity,
			summary: messageTitle,
			detail: message,
			life: 100000
		});
	}

	private progressBar(msgSeverity: string, messageTitle: string, message: string) {
		this.messageService.clear();
		const interval = setInterval(() => {
			this.value = this.value + Math.floor(Math.random() * 10) + 1;
			if (this.value >= 100) {
				this.value = 100;
				this.messageService.add({severity: msgSeverity, summary: messageTitle, detail: message});
				clearInterval(interval);
			}
		}, 2000);
	}

	onT1SupplierSiteNamesChange($event: any) {
		this.loadSupplierEmailsBasedOnT1SupplierSite();
		this.loadParentCodesBasedOnT1SupplierSite();
	}

	onParentCodeChange($event: any) {
		this.loadSupplierEmailsBasedOnT1SupplierSiteAndParentCode();
	}

	onEmailIdsChange($event: any) {
		if (this.selectedEmailIds.length > 0) {
			const emailIds = [];
			for (const item of this.selectedEmailIds) {
				emailIds.push(item.name);
			}
			this.t2Visibility.email = emailIds.join(';!');
		}
	}

	downloadGeneratedReport() {
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		workbook.save(opt, (d) => {
			this.t2VisibilityService.downLoadFile(d, 'text/csv');
		}, (e) => {
			this.pushMessage('error', 'Error', 'Unable to Download File, Please try again');
		});
	}

	uploadCleanedReport() {
		this.spinnerService.show();
		const opt = new WorkbookSaveOptions();
		opt.type = 'blob';
		const workbook = this.spreadsheet.workbook;
		// @ts-ignore
		// @ts-ignore
		workbook.save(opt, (d) => {
			const fileExt = ExcelUtility.getExtension(workbook.currentFormat);
			let flag;
			for (let i = 6; (workbook.worksheets(0).rows(i).cells(0).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(1).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(2).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(3).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(4).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(5).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(6).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(7).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(10).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(11).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(12).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(13).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(14).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(15).value) !== null
			|| (workbook.worksheets(0).rows(i).cells(16).value) !== null; i++) {

				const rowNumber = workbook.worksheets(0).rows(i).cells(0).rowIndex + 1;
				// console.log('cell 10 value: ', (workbook.worksheets(0).rows(i).cells(10).value));

				if ((workbook.worksheets(0).rows(i).cells(10).value) === 'Yes'
					|| (workbook.worksheets(0).rows(i).cells(10).value) === 'MFG Only') {
					if ((workbook.worksheets(0).rows(i).cells(0).value) === null
						|| ((workbook.worksheets(0).rows(i).cells(0).value).trim()).length !== 5
						|| (workbook.worksheets(0).rows(i).cells(0).value) === '') {
						flag = 1;
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Check GSDB Codes in row number :  ' + rowNumber);
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(1).value) === null
						|| (workbook.worksheets(0).rows(i).cells(1).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T1 Supplier Site Name in row number : ' + rowNumber);
						flag = 2;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(2).value) === null
						|| (workbook.worksheets(0).rows(i).cells(2).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (MFG) in row number : ' + rowNumber);
						flag = 3;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(3).value) === null
						|| (workbook.worksheets(0).rows(i).cells(3).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (MFG) in row number : ' + rowNumber);
						flag = 4;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(4).value) === null
						|| (workbook.worksheets(0).rows(i).cells(4).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (MFG) in row number : ' + rowNumber);
						flag = 5;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(5).value) === null
						|| (workbook.worksheets(0).rows(i).cells(5).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (MFG) in row number : ' + rowNumber);
						flag = 6;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(6).value) === null
						|| (workbook.worksheets(0).rows(i).cells(6).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (MFG) in row number : ' + rowNumber);
						flag = 7;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(7).value) === null
						|| (workbook.worksheets(0).rows(i).cells(7).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (MFG) in row number : ' + rowNumber);
						flag = 8;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else if ((workbook.worksheets(0).rows(i).cells(10).value) === 'Ship Only') {
					if ((workbook.worksheets(0).rows(i).cells(11).value) === null
						|| (workbook.worksheets(0).rows(i).cells(11).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (SHIP) in row number : ' + rowNumber);
						flag = 9;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(12).value) === null
						|| (workbook.worksheets(0).rows(i).cells(12).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (SHIP) in row number : ' + rowNumber);
						flag = 10;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(13).value) === null
						|| (workbook.worksheets(0).rows(i).cells(13).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (SHIP) in row number : ' + rowNumber);
						flag = 11;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(14).value) === null
						|| (workbook.worksheets(0).rows(i).cells(14).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (SHIP) in row number : ' + rowNumber);
						flag = 12;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(15).value) === null
						|| (workbook.worksheets(0).rows(i).cells(15).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (SHIP) in row number : ' + rowNumber);
						flag = 13;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(16).value) === null
						|| (workbook.worksheets(0).rows(i).cells(16).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (SHIP) in row number : ' + rowNumber);
						flag = 14;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else if ((workbook.worksheets(0).rows(i).cells(10).value) === null
					|| (workbook.worksheets(0).rows(i).cells(10).value) === ' ') {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'Please fill Column K from the dropdown in row number : ' + rowNumber);
					flag = 31;
					this.display = false;
					break;
				} else if ((workbook.worksheets(0).rows(i).cells(10).value) === 'No') {
					if ((workbook.worksheets(0).rows(i).cells(0).value) === null
						|| ((workbook.worksheets(0).rows(i).cells(0).value).trim()).length !== 5
						|| (workbook.worksheets(0).rows(i).cells(0).value) === '') {
						flag = 1;
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Check GSDB Codes in row number :  ' + rowNumber);
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(1).value) === null
						|| (workbook.worksheets(0).rows(i).cells(1).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T1 Supplier Site Name in row number : ' + rowNumber);
						flag = 2;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(2).value) === null
						|| (workbook.worksheets(0).rows(i).cells(2).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (MFG) in row number : ' + rowNumber);
						flag = 3;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(3).value) === null
						|| (workbook.worksheets(0).rows(i).cells(3).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (MFG) in row number : ' + rowNumber);
						flag = 4;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(4).value) === null
						|| (workbook.worksheets(0).rows(i).cells(4).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (MFG) in row number : ' + rowNumber);
						flag = 5;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(5).value) === null
						|| (workbook.worksheets(0).rows(i).cells(5).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (MFG) in row number : ' + rowNumber);
						flag = 6;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(6).value) === null
						|| (workbook.worksheets(0).rows(i).cells(6).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (MFG) in row number : ' + rowNumber);
						flag = 7;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(7).value) === null
						|| (workbook.worksheets(0).rows(i).cells(7).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (MFG) in row number : ' + rowNumber);
						flag = 8;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(11).value) === null
						|| (workbook.worksheets(0).rows(i).cells(11).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Supplier Site Name (SHIP) in row number : ' + rowNumber);
						flag = 9;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(12).value) === null
						|| (workbook.worksheets(0).rows(i).cells(12).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Address (SHIP) in row number : ' + rowNumber);
						flag = 10;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(13).value) === null
						|| (workbook.worksheets(0).rows(i).cells(13).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site City (SHIP) in row number : ' + rowNumber);
						flag = 11;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(14).value) === null
						|| (workbook.worksheets(0).rows(i).cells(14).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site State/Province (SHIP) in row number : ' + rowNumber);
						flag = 12;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(15).value) === null
						|| (workbook.worksheets(0).rows(i).cells(15).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Postal/Zipcode (SHIP) in row number : ' + rowNumber);
						flag = 13;
						this.display = false;
						break;
					} else if ((workbook.worksheets(0).rows(i).cells(16).value) === null
						|| (workbook.worksheets(0).rows(i).cells(16).value) === '') {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Please Fill T2 Site Country (SHIP) in row number : ' + rowNumber);
						flag = 14;
						this.display = false;
						break;
					} else {
						flag = 18;
						continue;
					}
				} else {
					this.spinnerService.hide();
					this.pushMessage('error', 'error',
						'The Value in Column K must be either "Yes" or "No" in row number : ' + rowNumber);
					flag = 30;
					this.display = false;
					break;
				}
			}
			if (flag === 18) {
				// tslint:disable-next-line:max-line-length
				this.t2VisibilityService.uploadCleanedReport(d as File).subscribe((data) => {
						this.spinnerService.hide();
						this.pushMessage('success', 'success',
							'Data Updated Successfully!');
						// this.submitStatus = false;
						this.display = false;
						// this.spreadsheet.workbook = new Workbook();
						// this.initialLoad();

					},
					error => {
						this.spinnerService.hide();
						this.pushMessage('error', 'error',
							'Unable to Update Data, Please try again later');
					}
				);
			}

		}, (e) => {
			this.spinnerService.hide();
			this.pushMessage('error', 'Error', 'Unable to Update Data, Please try again later');
		});

	}

	public openFileForUpload(input: any): void {
		this.spinnerService.show();
		if (input.files == null || input.files.length === 0) {
			return;
		}
		this.t2VisibilityService.uploadCleanedReport(input.files[0]).subscribe((data) => {
				this.spinnerService.hide();
				this.pushMessage('success', 'success',
					'Data Updated Successfully!');
				// this.submitStatus = false;
				this.display = false;
				// this.spreadsheet.workbook = new Workbook();
				// this.initialLoad();

			},
			error => {
				this.spinnerService.hide();
				this.pushMessage('error', 'error',
					'Unable to Update Data, Please try again later');
			}
		);
		// ExcelUtility.load(input.files[0]).then((w) => {
		// 	this.spreadsheet.workbook = w;
		// 	this.uploadCleanedReport();
		// 	this.spinnerService.hide();
		// 	// this.submitRemainder = true;
		// 	console.log(this.spreadsheet.workbook.worksheets('T2')._r);
		//
		// }, (e) => {
		// 	console.error('Workbook Load Error:' + e);
		// });
	}

	downloadCleanedReport() {
		this.spinnerService.show();
		this.pushMessage('warn', 'Warning', 'Report Generation may take some time');
		this.t2VisibilityService.downloadT2CleanedReport().subscribe((data) => {
			this.fileFromDatabase = data;
			this.downloadOffline(this.fileFromDatabase);
			ExcelUtility.load(this.fileFromDatabase).then((w) => {
				this.spreadsheet.workbook = w;
				console.log('Excel Loaded');
				this.spinnerService.hide();
			});
		});
	}
}
