import {Component, OnInit} from '@angular/core';
import {SupplierService} from '../../../home/services/supplier.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {T2VisibilityService} from '../services/t2-visibility.service';
import {emptyT2Admin, T2VisibilityAdmin} from '../model/t2_visibility_admin';
import {DatePipe} from '@angular/common';

@Component({
	selector: 't2-visibility-admin',
	templateUrl: './t2-visibility-admin.component.html',
	styleUrls: ['./t2-visibility-admin.component.scss']
})
export class T2VisibilityAdminComponent implements OnInit {
	t2VisbilityNotificationForm: FormGroup;
	isDisabled = false;
	cols: any[];
	// tslint:disable-next-line:ban-types
	notifications: any[] = [];
	display = false;
	t2VisibilityAdmin: T2VisibilityAdmin;
	isT2NotificationFormValid = false;
	updateNotification: T2VisibilityAdmin;
	isCreateButtonVisible = false;
	isUpdateButtonVisible = false;
	// tslint:disable-next-line:max-line-length
	constructor(private supplierService: SupplierService, private router: Router, private fb: FormBuilder,  private datepipe: DatePipe, private messageService: MessageService, private t2VisibilityService: T2VisibilityService) {
	}

	ngOnInit() {
		// this.supplierService.pushSelectedSurvey('T2Visibility');
		let businessName = sessionStorage.getItem('Admin');

		if (businessName === 'T2Visibility' || businessName === null) {
			this.router.navigate(['/']);
			return;
		}

		if (sessionStorage.getItem('uid') == null) {
			this.router.navigate(['/']);
			return;
		} else if (sessionStorage.getItem('uid').includes('-')) {
			this.router.navigate(['/']);
			return;
		}
		if (businessName === 'T2Visibility') {
			// console.log('T2Visibility');
			this.supplierService.pushSelectedSurvey('T2Visibility');
		} else if (businessName === 'T2Visibility Data Refresh') {
			// console.log('T2Visibility Data Refresh');
			this.supplierService.pushSelectedSurvey('T2Visibility Data Refresh');
		}
		this.cols = [
			{field: 'id', header: 'S.No'},
			{field: 'subject', header: 'Notification Subject'},
			{field: 'startDate', header: 'From Date'},
			{field: 'endDate', header: 'To Date'},
			{field: 'createdBy',  header: 'Created (UTC)'},
			{field: 'createdOn',  header: ''},
			{field: 'updatedBy',  header: 'Updated (UTC)'},
			{field: 'updatedOn', header: ''}
		];
		this.t2VisbilityNotificationForm = this.fb.group({
			subject: [{value: '', disabled: this.isDisabled}],
			startDate: [{value: '', disabled: this.isDisabled}, Validators.required],
			endDate: [{value: '', disabled: this.isDisabled}, Validators.required],
		});
		this.findAllAdminNotifications();
	}
	private findAllAdminNotifications() {
		this.t2VisibilityService.getAllAdminNotifications().subscribe((data) => {
			this.notifications = data;
		});
	}

	showCreateDialog() {
		this.t2VisbilityNotificationForm.reset();
		this.display = true;
		this.isCreateButtonVisible = true;
		this.isUpdateButtonVisible = false;
	}

	create() {
		this.t2VisibilityAdmin = this.t2VisbilityNotificationForm.getRawValue() as T2VisibilityAdmin;
		// @ts-ignore
		this.t2VisibilityAdmin.startDate = this.datepipe.transform(this.t2VisbilityNotificationForm.get('startDate').value, 'yyyy-MM-dd');
		// @ts-ignore
		this.t2VisibilityAdmin.endDate = this.datepipe.transform(this.t2VisbilityNotificationForm.get('endDate').value, 'yyyy-MM-dd');
		if (!this.t2NotificationFormValid(this.t2VisibilityAdmin)) {
			this.isT2NotificationFormValid = false;
			this.pushMessage('error', 'Error', 'Please select the start date and end date for a notification.');
		} else {
			this.isT2NotificationFormValid = true;
		}
		console.log(this.t2VisibilityAdmin);
		if (this.isT2NotificationFormValid) {
			this.t2VisibilityService.createNotification(this.t2VisibilityAdmin).subscribe(response => {
				this.t2VisibilityAdmin = response;
				console.log('Response in the service : ', response);
				this.pushMessage('success', 'Success', 'New Notification message has been Created successfully');
				this.t2VisbilityNotificationForm.reset();
				this.display = false;
				this.findAllAdminNotifications();
			});
		}
	}

	private t2NotificationFormValid(t2VisibilityAdmin: T2VisibilityAdmin): boolean {
			let isT2NotificationFormValid = false;
			if (t2VisibilityAdmin.startDate !== null && t2VisibilityAdmin.endDate !== null ) {
				isT2NotificationFormValid = true;
			}
			return isT2NotificationFormValid;
		}

	showUpdateDialog(data: any) {
		this.display = true;
		this.isCreateButtonVisible = false;
		this.isUpdateButtonVisible = true;
		this.updateNotification = new T2VisibilityAdmin(emptyT2Admin);
		this.updateNotification.id = data['id'];
		this.updateNotification.createdBy = data['createdBy'];
		this.updateNotification.createdOn = data['createdOn'];

		this.updateNotification.subject = data['subject'];
		this.updateNotification.startDate = data['startDate'];
		this.updateNotification.endDate = data['endDate'];
		this.t2VisbilityNotificationForm.patchValue({subject: data['subject']});
		this.t2VisbilityNotificationForm.patchValue({startDate: data['startDate']});
		this.t2VisbilityNotificationForm.patchValue({endDate: data['endDate']});
		console.log('Patch value: ', data);
	}

	update() {
			this.updateNotification.subject = this.t2VisbilityNotificationForm.get('subject').value;
		// tslint:disable-next-line:indent
		 // @ts-ignore
		// tslint:disable-next-line:indent
		 this.updateNotification.startDate = this.datepipe.transform(this.t2VisbilityNotificationForm.get('startDate').value, 'yyyy-MM-dd');
		// @ts-ignore
		// tslint:disable-next-line:indent
		 this.updateNotification.endDate = this.datepipe.transform(this.t2VisbilityNotificationForm.get('endDate').value, 'yyyy-MM-dd');
			this.t2VisibilityService.updateNotification(this.updateNotification).subscribe((data) => {
				console.log('Success: Schedule Id #' + data.subject + ' has been Updated successfully');
				this.pushMessage('success', 'Success', 'Notification Id #' + data.id + ' has been Updated successfully');
				this.display = false;
				this.t2VisbilityNotificationForm.reset();
				this.findAllAdminNotifications();

			}, error => {
				this.findAllAdminNotifications();
				this.pushMessage('error', 'Error', 'Please verify the data');
				this.display = false;
				this.t2VisbilityNotificationForm.reset();
			});
		}

		private pushMessage(msgSeverity: string, messageTitle: string, message: string) {
			this.messageService.clear();
			this.messageService.add({
				severity: msgSeverity,
				summary: messageTitle,
				detail: message,
				life: 60000
			});
		}

}
